<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="addSchoolYearValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.school_year") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.school_year')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='school_year'>{{$t('general.school_year')}}</label>
                                <b-form-input id='school_year' v-model="schoolYear"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.title')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='title'>{{$t('general.title')}}</label>
                                <b-form-input id='title' v-model="title"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                :name="$t('general.subtitle')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='subtitle'>{{$t('general.subtitle')}}</label>
                                <b-form-input id='subtitle' v-model="subtitle"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col><h5>{{ $t("general.possibleRegister") }}</h5></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <validation-provider
                                name="Prijava od"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='registerFrom'>Prijava od</label>
                                <b-form-datepicker id='registerFrom' v-model="registerFrom" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                name="Prijava do"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='registerTo'>Prijava do</label>
                                <b-form-datepicker id='registerTo' v-model="registerTo" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col><h5>{{ $t("general.possibleSubmit") }}</h5></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <validation-provider
                                name="Oddaja od"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='submitFrom'>Oddaja od</label>
                                <b-form-datepicker id='submitFrom' v-model="submitFrom" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col>
                            <validation-provider
                                name="Oddaja do"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='submitTo'>Oddaja do</label>
                                <b-form-datepicker id='submitTo' v-model="submitTo" :date-format-options="dateFormat" start-weekday="1"></b-form-datepicker>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BFormInput, BButton, BFormDatepicker} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    export default {
        components: {BCol,
                     BRow,
                     BOverlay,
                     BCard,
                     BForm,
                     BFormInput,
                     BButton,
                     BFormDatepicker,
                     ValidationProvider,
                     ValidationObserver},
        data() {
            return {
                dateFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
                schoolYear: '',
                title: '',
                subtitle: '',
                registerFrom: '',
                registerTo: '',
                submitFrom: '',
                submitTo: '',
                showLoader: false,
                required
            }
        },
        methods: {
            validationForm() {
                this.$refs.addSchoolYearValidation.validate().then((response) => {
                    if (response) {
                        this.onAddSchoolYear()
                    }
                })
            },
            onAddSchoolYear() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.schoolYear,
                    Title: thisIns.title,
                    Subtitle: thisIns.subtitle,
                    StartDateRegister: thisIns.registerFrom,
                    EndDateRegister: thisIns.registerTo,
                    StartDateTurnIn: thisIns.submitFrom,
                    EndDateTurnIn: thisIns.submitTo
                }
                thisIns.$http.put('/v1/admin/solskoLeto/', payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.schoolYearSuccess')}`)
                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'school-year'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created()  {
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>