var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('validation-observer',{ref:"addSchoolYearValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v(_vm._s(_vm.$t("general.school_year")))])]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('general.go_back')))])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.school_year'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"school_year"}},[_vm._v(_vm._s(_vm.$t('general.school_year')))]),_c('b-form-input',{attrs:{"id":"school_year"},model:{value:(_vm.schoolYear),callback:function ($$v) {_vm.schoolYear=$$v},expression:"schoolYear"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('general.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t('general.title')))]),_c('b-form-input',{attrs:{"id":"title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('general.subtitle'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"subtitle"}},[_vm._v(_vm._s(_vm.$t('general.subtitle')))]),_c('b-form-input',{attrs:{"id":"subtitle"},model:{value:(_vm.subtitle),callback:function ($$v) {_vm.subtitle=$$v},expression:"subtitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.$t("general.possibleRegister")))])])],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Prijava od","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"registerFrom"}},[_vm._v("Prijava od")]),_c('b-form-datepicker',{attrs:{"id":"registerFrom","date-format-options":_vm.dateFormat,"start-weekday":"1"},model:{value:(_vm.registerFrom),callback:function ($$v) {_vm.registerFrom=$$v},expression:"registerFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Prijava do","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"registerTo"}},[_vm._v("Prijava do")]),_c('b-form-datepicker',{attrs:{"id":"registerTo","date-format-options":_vm.dateFormat,"start-weekday":"1"},model:{value:(_vm.registerTo),callback:function ($$v) {_vm.registerTo=$$v},expression:"registerTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.$t("general.possibleSubmit")))])])],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Oddaja od","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"submitFrom"}},[_vm._v("Oddaja od")]),_c('b-form-datepicker',{attrs:{"id":"submitFrom","date-format-options":_vm.dateFormat,"start-weekday":"1"},model:{value:(_vm.submitFrom),callback:function ($$v) {_vm.submitFrom=$$v},expression:"submitFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Oddaja do","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"submitTo"}},[_vm._v("Oddaja do")]),_c('b-form-datepicker',{attrs:{"id":"submitTo","date-format-options":_vm.dateFormat,"start-weekday":"1"},model:{value:(_vm.submitTo),callback:function ($$v) {_vm.submitTo=$$v},expression:"submitTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }